/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import play from "../../assets/images/casinogame/gamecard/play.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
// import { startselectedGame } from "../../utility/Apis";
import { Spinner } from "react-bootstrap";
// import { useMyContext } from "../context";
//import GamePopup from "../casino/gamePopup";
import { useMyContext } from "../context";
import { getLandingGames } from "../../utility/Apis";
import { toast } from "react-toastify";

function Lobby({ setShowLobbyGames }) {
  const {
    loading,
    setLoading,
    userDetails,
    setIsGamePlaying,
    handleLoginShow,
  } = useMyContext();
  const [landingGames, setLandingGames] = useState([]);
  // const { gameShow, setGameShow } = useMyContext();
  const [isPrevButtonDisabled, setPrevButtonDisabled] = useState(true);
  const [isNextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  // const handleGameShow = () => {
  //   setGameShow(true);
  // };

  // const handleGameClose = () => {
  //   navigate("/");
  //   setGameShow(false);
  //   setIsGamePlaying(false);
  // };

  // const headerGamePopup = gameShow && "gamePopup";

  // landing games api is calling here
  const fetchLandingGames = async () => {
    try {
      setLoading(true);
      const res = await getLandingGames();
      if (res?.msg === "success") {
        setLandingGames(res?.data?.[0]);
      } else {
        toast.error(res?.msg, { toastId: "game-error" });
      }
    } catch (error) {
      toast.error(error?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLandingGames();
    // eslint-disable-next-line
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 5,
    rows: 2,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2.5,
        },
      },
    ],
  };

  const navigate = useNavigate();

  // const [game, setGame] = useState({});
  const sliderRefs = {
    "New Games": React.createRef(),
    Slots: React.createRef(),
    "Table Game": React.createRef(),
    "Video Poker": React.createRef(),
    "Table & Cards": React.createRef(),
    "Instant Win": React.createRef(),
    Poker: React.createRef(),
    // "Live Dealer": React.createRef(),
  };
  const updateButtonState = (category) => {
    if (
      sliderRefs[category] &&
      sliderRefs[category].current &&
      sliderRefs[category].current.innerSlider
    ) {
      const currentSlide =
        sliderRefs[category].current.innerSlider.state.currentSlide;
      setCurrentSlideIndex(currentSlide);
      setPrevButtonDisabled(currentSlide === 0);

      setNextButtonDisabled(
        currentSlide === landingGames[category].length - settings.slidesToShow
      );
    }
    console.log(
      "isPrevButtonDisabled==>",
      isPrevButtonDisabled,
      isNextButtonDisabled,
      currentSlideIndex
    );
  };

  const [nextArray, setNextArray] = useState([]);
  const [preArray, setPreArray] = useState([]);

  const gameArray = Object.keys(landingGames);

  useEffect(() => {
    if (gameArray?.length) {
      setPreArray([...gameArray?.map((el, index) => index)]);
    }
  }, [gameArray?.length]);

  const goToSlide = (category, index, length, direction) => {
    const slideCategoryLength =
      sliderRefs[category].current.innerSlider.state.currentSlide;
    const difference = length - slideCategoryLength;

    if (direction === "previous") {
      if (difference >= 5) {
        setNextArray(updateArray(nextArray, index, "remove"));
      }

      if (slideCategoryLength === 1) {
        setPreArray(updateArray(preArray, index, "add"));
      }

      sliderRefs[category].current.slickPrev();
    } else if (direction === "next") {
      if (difference === 6) {
        setNextArray(updateArray(nextArray, index, "add"));
      }

      if (slideCategoryLength === 0) {
        setPreArray(updateArray(preArray, index, "remove"));
      }

      if (difference !== 5) {
        sliderRefs[category].current.slickNext();
      }
    }

    updateButtonState(category);
  };

  const updateArray = (array, index, action) => {
    const copy = [...array];
    const fIndex = copy.indexOf(index);
    if (action === "add" && fIndex === -1) {
      copy.push(index);
    } else if (action === "remove" && fIndex >= 0) {
      copy.splice(fIndex, 1);
    }
    return copy;
  };

  const handleAfterChange = (category) => {
    updateButtonState(category);
  };
  const handleClick = (name, id, filterBy) => {
    if (name === "New Games") {
      navigate("/view-all/false/lobby/lobby");
      setShowLobbyGames(true);
    } else if (filterBy === "category") {
      navigate(`/view-all/false/${id}/${name?.replace(/\s+/g, "-")}`);
      setShowLobbyGames(false);
    }
  };

  const handleClickPlay = (game) => {
    if (!userDetails?.email) {
      handleLoginShow();
    } else {
      navigate(`/game/${game?.masterCasinoGameId}`);
      // setGame(game);
      setIsGamePlaying(true);
    }
  };

  return (
    <>
      <section className="mini-games game-head-wrapper">
        {loading ? (
          <div style={{ zIndex: 99, textAlign: "center" }}>
            <Spinner
              as="span"
              animation="border"
              size="md"
              role="status"
              aria-hidden="true"
              variant="light"
              style={{ height: "50px", width: "50px" }}
            />
          </div>
        ) : (
          Object.keys(landingGames)?.map((category, index) => (
            <div className="mini-games-wrapper" key={index}>
              <div className="head-wrapper">
                <div>
                  <span>{}</span>
                  <h1 className="title">{category}</h1>
                </div>
                <div className="right">
                  <div
                    className="view-more"
                    onClick={() =>
                      handleClick(
                        category,
                        landingGames[category][0]?.masterGameSubCategoryId,
                        "category"
                      )
                    }
                  >
                    <Link>See all</Link>
                    <span className="count">
                      {landingGames[category]?.length}
                    </span>
                  </div>
                  <div className="slider-btn-wrapper">
                    <div
                      className={`swiper-prev ${
                        preArray.includes(index)
                          ? "swiper-button-disabled"
                          : " "
                      }`}
                      onClick={() =>
                        goToSlide(
                          category,
                          index,
                          landingGames[category]?.length,
                          "previous"
                        )
                      }
                    ></div>
                    <div
                      className={`swiper-next ${
                        nextArray.includes(index)
                          ? "swiper-button-disabled"
                          : ""
                      }`}
                      onClick={() =>
                        goToSlide(
                          category,
                          index,
                          landingGames[category]?.length,
                          "next"
                        )
                      }
                    ></div>
                  </div>
                </div>
              </div>
              <div className="game-swiper-container ">
                <Slider
                  ref={sliderRefs[category]}
                  {...settings}
                  afterChange={() => handleAfterChange(category)}
                >
                  {landingGames[category]?.map((game) => (
                    <div key={game?.masterCasinoGameId}>
                      <div
                        className="game-swiper-slider"
                        key={game?.masterCasinoGameId}
                        // onClick={() => startGame(game?.masterCasinoGameId)}
                      >
                        <div className="game-swiper-slider-img">
                          <Link to="/" key={game?.masterCasinoGameId}>
                            <img src={game && game?.thumbnailUrl} alt="" />
                          </Link>
                          <div
                            className="slot-hover"
                            onClick={() => handleClickPlay(game)}
                          >
                            <span
                            // onClick={handleGameShow}
                            >
                              <img
                                className="play"
                                src={play}
                                alt="play icon"
                                onClick={() => handleClickPlay(game)}
                              />
                            </span>
                            <div
                              className="star-wrapper"
                              // onClick={handleChangeFav}
                            >
                              <span className="material-icons">
                                {true ? "favorite_border" : "favorite"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p
                        className="pt-2 text-center game-title"
                        style={{ marginRight: "33px" }}
                      >
                        {game?.name?.length > 30
                          ? game?.name?.substring(0, 30) + "..."
                          : game?.name}
                      </p>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          ))
        )}
      </section>
    </>
  );
}

export default Lobby;
