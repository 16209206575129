import React, { useState } from "react";
import closeIcon from "../../assets/images/header/auth/icon/close.svg";
import authbanner from "../../assets/images/header/auth/jackbit-banner.png";
import username from "../../assets/images/header/auth/icon/username43a0.svg";
import password from "../../assets/images/header/auth/icon/password43a0.svg";
import email from "../../assets/images/header/auth/icon/email43a0.svg";
import accept from "../../assets/images/header/auth/icon/accept5ed3.svg";
import { useForm } from "react-hook-form";
import { registerUser } from "../../utility/Apis";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useMyContext } from "../context";
import { Spinner } from "react-bootstrap";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function SignupPopup({ handleSignupClose, handleLoginShow, handleSignupShow }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { loading, setLoading } = useMyContext();
  const [showPassword, setShowPassword] = useState(false);
  // ======react query api call =======================
  const mutation = useMutation({
    mutationFn: async (userData) => await registerUser(userData),
    onMutate: () => {
      // Set loading to true when the mutation starts
      setLoading(true);
    },
    onSuccess: (data) => {
      // const {status } = data || {};
      if (data?.status === 200) {
        // console.log("hjbcdsdss---", token);
        toast.success("Register Succcessfully", {
          toastId: "register-success",
          autoClose: 2000,
        });
        setLoading(false);
        handleSignupClose();
        handleLoginShow();
      } else {
        setLoading(false);
        console.log("Something went wrong");
        toast.error(data?.message, {
          toastId: "register-error",
          autoClose: 2000,
        });
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    onSettled: () => {
      // Reset loading state when the mutation is completed (success or failure)
      setLoading(false);
    },
  });

  // Regular expression pattern for username
  // function isValidUsername(username) {
  //   // Regular expression pattern for username
  //   const usernameRegex = /^[a-zA-Z0-9_-]{3,16}$/;

  //   // Test the username against the regex pattern
  //   return usernameRegex.test(username);
  // }
  // Regular expression pattern for email
  // const emailRegex =
  //   /^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/;

  // function isValidEmail(email) {
  //   return emailRegex.test(email);
  // }

  const formSubmit = (data, e) => {
    e.preventDefault();
    mutation.mutate(data);
  };

  return (
    <div className="overlay signup-popup">
      <div className="popup box">
        <span className="close" onClick={handleSignupClose}>
          <img src={closeIcon} alt="closeIcon" />
        </span>
        <div className="content">
          <div className="banner">
            <img src={authbanner} alt="auth-banner" />
          </div>
          <form className="sign-up" onSubmit={handleSubmit(formSubmit)}>
            <ul className="menu">
              <li
                className="login"
                text_key="LOGIN__LOG_IN"
                onClick={handleLoginShow}
              >
                Log In
              </li>
              <li
                className="signup active"
                text_key="LOGIN__SIGN_UP"
                onClick={handleSignupShow}
              >
                Sign Up
              </li>
            </ul>
            <ul className="fields">
              <li>
                <input
                  type="text"
                  name="userName"
                  placeholder="Username"
                  placeholder_key="SIGNUP__USERNAME"
                  autoComplete="off"
                  {...register("userName", {
                    required: "Username is required",
                    pattern: {
                      value: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9_]+$/,
                      message: "Username must only contain letters, numbers",
                    },
                    minLength: {
                      value: 8,
                      message: "Username must be at least 8 characters long",
                    },
                    maxLength: {
                      value: 20,
                      message: "Username must not exceed 20 characters",
                    }, // Adding maxLength validation
                  })}
                  className={`${errors?.userName && "dirty invalid"}`}
                />
                <img src={username} alt="username" />
              </li>
              {errors?.userName && errors?.userName?.message && (
                <li
                  className="err-text"
                  field="UserName"
                  style={{ textAlign: "start" }}
                >
                  {errors?.userName?.message}
                </li>
              )}
              <li>
                <input
                  type={!showPassword ? "password" : "text"}
                  name="password"
                  placeholder="Password"
                  placeholder_key="SIGNUP__PASSWORD"
                  autoComplete="off"
                  {...register("password", {
                    required: "Password is required",
                    pattern: {
                      value:
                        // eslint-disable-next-line
                        /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':".,/<>?\|`~])(?=.*\d)(?=.{8,})/,
                      message:
                        "Password must contain at least one uppercase letter, one special character, one number, and be at least 8 characters long",
                    },
                  })}
                  className={`${
                    errors?.password && "dirty invalid"
                  } input-sub-icon`}
                />
                {!showPassword ? (
                  <Visibility
                    role="button"
                    className="show-password"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                ) : (
                  <VisibilityOff
                    role="button"
                    className="show-password"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  />
                )}
                <img src={password} alt="password" />
              </li>

              {errors?.password && errors?.password?.message && (
                <li
                  className="err-text"
                  field="Password"
                  style={{ textAlign: "start" }}
                >
                  {errors?.password?.message}
                </li>
              )}

              <li>
                <input
                  type="text"
                  name="agentId"
                  placeholder="Agent ID"
                  placeholder_key="AGENT_ID"
                  autoComplete="off"
                  {...register("agentId", {
                    required: "Agent ID is required",
                    pattern: {
                      value:
                        // eslint-disable-next-line
                        /^[0-9]{5}$/,
                      message: "Agent ID must be in a valid format",
                    },
                  })}
                  className={`${errors?.agentId && "dirty invalid"}`}
                  onKeyDown={(e) => {
                    // Prevent entering non-numeric values (except for navigation keys like backspace)
                    if (e.key.match(/[^0-9]/) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                />

                <img src={email} alt="email" />
              </li>

              {errors.agentId && errors.agentId.message && (
                <li
                  className="err-text"
                  field="Email"
                  style={{ textAlign: "start" }}
                >
                  {errors?.agentId?.message}
                </li>
              )}

              <li>
                <label className="checkbox-wrapper">
                  <input type="checkbox" required />
                  <div className="checkmark">
                    <img src={accept} alt="accept" />
                  </div>
                  <a
                    href="/"
                    target="_blank"
                    className="confirmation"
                    text_key="SIGNUP__AGREE_TERMS_AND_CONDITIONS"
                  >
                    Agree terms and conditions
                  </a>
                </label>
              </li>
              <li>
                <button
                  className="btn"
                  text_key="SIGNUP__REGISTER"
                  type="submit"
                >
                  {loading ? (
                    <div style={{ zIndex: 99, textAlign: "center" }}>
                      <Spinner
                        as="span"
                        animation="border"
                        size="md"
                        role="status"
                        aria-hidden="true"
                        variant="light"
                      />
                    </div>
                  ) : (
                    "Register"
                  )}
                </button>
              </li>
              {/* <!-- <li>
                    <p text_key="SIGNUP__OR_LOGIN_WITH"></p>
                    <ul className="social-auth">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </li> --> */}
              <li className="account-auth">
                <p>
                  <span text_key="LOGIN__DO_YOU_HAVE_AN_ACCOUNT?">
                    Do you have an account?
                  </span>
                  <b
                    className="login"
                    text_key="LOGIN__LOG_IN"
                    onClick={handleLoginShow}
                  >
                    Log In
                  </b>
                </p>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignupPopup;
